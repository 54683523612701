



















import { getProRank } from '@/api/product';
import { IBaseProduct } from '@/api/type/base';
import { Vue, Component } from 'vue-property-decorator';
@Component({
  name: ''
})
export default class extends Vue {
  numberImgs = [
    '//means-static.luckyop.com/images/20210817/84904ca87eb04e83b24d447be3478ddd.png',
    '//means-static.luckyop.com/images/20210817/950d8aab188a445d81358e93f8f532c5.png',
    '//means-static.luckyop.com/images/20210817/5f2376b0d9384863b3e282f1c462366b.png'
  ];

  proList = [] as IBaseProduct[];
  async getList() {
    const { payload } = await getProRank();
    this.proList = payload;
  }

  calcCount(count: number) {
    if (count < 10) {
      return count;
    } else {
      return Math.floor(count / 10) * 10 + '+';
    }
  }
  created() {
    this.getList();
  }
}
